window.require = require
window.jQuery = window.$ = require('jquery')


var Tripartite = require('tripartite')
/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

require('page-editor').configure()

$('.notify-box .notify-me a').click(function(evt) {
	evt.preventDefault()
	$('.notify-box').addClass('show-form')
})

$('form.activate-form').on('submit', function(evt) {
    evt.preventDefault()
	$('form.activate-form button').prop('disabled', true).css('background-color', '#777777')
    var $this = $(this)
    $.ajax({
        type: "POST",
        url: $this.attr('action'),
        data: $('form.activate-form').serialize()
    }).done(function (data) {
        $('.notify-box').removeClass('show-form').addClass('thanks')
    })
})


$('.single-page-landing #notes').val('I look forward to learning more about the services offered by Health-n-Healing Services.')
