var $ = require('jquery')
var Uploader = require('./pic-upload')
var PicBrowser = require('./pic-browser')
var Dialog = require('ei-dialog')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./image-in-input.tmpl'))
var templates = tri.templates


var addStylesIfNeeded = function() {
	if($('#image-in-input-styles').length == 0) {
		$('head').append('<style type="text/css" id="image-in-input-styles">' +
		templates['imageInInputStyles']() +
		'</style>')
	}
}


var imageInInput = function(inputControl, imagesSource, newImagePost, imagePrefix) {
	this.$inputControl = $(inputControl)
	this.imagesSource = imagesSource
	this.newImagePost = newImagePost
	this.imagePrefix = imagePrefix
}

imageInInput.prototype.render = function() {
	addStylesIfNeeded()
	var self = this
	this.$widget = $(templates['imageReplacementWidget']())
	this.$inputControl.after(this.$widget)
	this.$inputControl.attr('type', 'hidden')
	this.$widget.find('.change').click(function(evt) {
		evt.preventDefault()
		self.browser = new PicBrowser(self.imagesSource(), function(chosen) {
			var fin = new Image()
			var $final = self.$widget.find('.image-holder').html(fin)
			fin.src = chosen.fullSize
			self.diag.close()
			self.$inputControl.val(chosen.fullSize)
			self.uploader.cleanup()
		})
		
		self.diag = new Dialog({
			title: 'Choose Image',
			body: function(container) { 
				$(container).append('<p>Choose an image or drag and drop a new image or paste an image (ctrl-v).</p>')
				self.browser.render(container)
				return ''
			},
			on: {
				'.btn-ok': function() {
					var fin = new Image()
					var $final = self.$widget.find('.image-holder').html(fin)
					fin.src = self.uploader.getImageData()
					self.$inputControl.val(self.imagePrefix + self.uploader.getImageName())
					self.newImagePost(self.uploader.getImageData(), self.uploader.getImageName())
					self.uploader.cleanup()
				},
				'.btn-cancel': function() {
				}
			}
		})
		self.diag.open()

		var $diagBody = $('.dialog-frame .body')
		self.uploader = new Uploader($diagBody, $diagBody)
		self.uploader.render()
	})
	
	var fin = new Image()
	var $final = self.$widget.find('.image-holder').html(fin)
	fin.src = this.$inputControl.val()
	
	return this
}

imageInInput.prototype.cleanup = function() {
	this.$inputControl.attr('type', 'text')
	this.$widget.remove()
	return this
}

module.exports = imageInInput
